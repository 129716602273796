import { createContext, useContext, useState } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("auth") || null
  );

  const login = (token) => {
    // Set the received token in both the state and local storage
    setAuthToken(token);
    localStorage.setItem("auth", token);
  };

  const logout = () => {
    // Remove the token from both the state and local storage
    setAuthToken(null);
    localStorage.removeItem("auth");
  };

  const isAuthenticated = () => !!authToken;

  return (
    <AuthContext.Provider value={{ authToken, login, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
