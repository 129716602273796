import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../AuthContext"; // Import the useAuth hook
import "./css/style.css";

const columns = [
  { field: "id", headerName: "ID" },
  { field: "name", headerName: "Name", width: "100" },
  { field: "phone", headerName: "Number", width: "150" },
  { field: "email", headerName: "Email", width: "250" },
  { field: "instagram", headerName: "Instagram", width: "150" },
  { field: "facebook", headerName: "Facebook", width: "150" },
  { field: "twitter", headerName: "Twitter", width: "150" },
  { field: "linkedin", headerName: "Linkedin", width: "100" },
  { field: "google_reviews", headerName: "Google Review", width: "100" },
  { field: "address_link", headerName: "Address", width: "100" },
  {
    field: "actions",
    headerName: "Actions",
    width: 250,
    renderCell: (x) => (
      <div>
        <Stack direction="row" spacing={1}>
          <Button variant="contained" endIcon={<SendIcon />}>
            <Link
              to={`editcustomer/${x.id}`}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Edit
            </Link>
          </Button>
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={() => {
              // Call deleteData with the id
              deleteData(x.id);
            }}
          >
            Delete
          </Button>
        </Stack>
      </div>
    ),
  },
];

// Declare deleteData function outside the component
const useTableData = () => {
  const [tableData, setTableData] = useState([]);

  return { tableData, setTableData };
};

// Declare deleteData function outside the component
const deleteData = (id, setTableData) => {
  axios
    .post(
      `https://api.unicorndigitalmarketing.in/api/api_v1.php?type=deleteCustomer`,
      {
        id: id,
      }
    )
    .then((res) => {
      console.log(res.data);
      // Update the local state to remove the deleted row
      setTableData((prevData) => prevData.filter((row) => row.id !== id));
      alert("Customer Data Deleted Successfully");
    })
    .catch((err) => {
      console.error("Error deleting data:", err);
    });
};

const Dashboard = () => {
  const { isAuthenticated } = useAuth(); // Use the useAuth hook
  const { tableData, setTableData } = useTableData(); // Use the custom hook

  useEffect(() => {
    // Check authentication status and redirect to login if not authenticated
    if (!isAuthenticated()) {
      return <Navigate to="/" />;
    }

    // Fetch data if authenticated
    fetch(
      "https://api.unicorndigitalmarketing.in/api/api_v1.php?type=getAllCustomer"
    )
      .then((res) => res.json())
      .then((res) => {
        setTableData(res.customers);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isAuthenticated, setTableData]);

  return (
    <div style={{ height: 700, width: "100%" }}>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={10}
        slots={{
          toolbar: GridToolbar,
        }}
      />
    </div>
  );
};

export default Dashboard;
