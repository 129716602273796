import React from "react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import "./css/style.css";
import axios from "axios";
import { MDBFile } from "mdb-react-ui-kit";
import Avatar from "@mui/material/Avatar";
import { useAuth } from "../AuthContext";

const Editcustomer = () => {
  const { isAuthenticated } = useAuth();
  let [profile_image, setImage] = useState();
  let [name, setData] = useState("");
  let [designation, setDesignation] = useState("");
  let [description, setDescription] = useState("");
  let [phone, setNumber] = useState("");
  let [whatsapp, setWhatsapp] = useState("");
  let [email, setEmail] = useState("");
  let [instagram, setInsta] = useState("");
  let [facebook, setFb] = useState("");
  let [tik_tok, setTiktok] = useState("");
  let [twitter, setTwitter] = useState("");
  let [youtube, setYoutube] = useState("");
  let [linkedin, setLinkedin] = useState("");
  let [google_reviews, setGoogle] = useState("");
  let [website, setWebsite] = useState("");
  let [dummy, setDummy] = useState("");
  let [address_link, setAddress] = useState("");
  let [gallery_link1, setGallery1] = useState();
  let [gallery_link2, setGallery2] = useState();
  let [gallery_link3, setGallery3] = useState();
  let [gallery_link4, setGallery4] = useState();
  let [gallery_link5, setGallery5] = useState();
  let [pdf, setPdf] = useState();

  let { abc } = useParams();

  useEffect(() => {
    axios
      .post(
        `https://api.unicorndigitalmarketing.in/api/api_v1.php?type=getSingleCustomer`,
        {
          id: abc,
        }
      )
      .then((data) => {
        console.log(data);
        setImage(data.data.customer.profile_image);
        setData(data.data.customer.name);
        setDesignation(data.data.customer.designation);
        setDescription(data.data.customer.description);
        setNumber(data.data.customer.phone);
        setWhatsapp(data.data.customer.whatsapp);
        setEmail(data.data.customer.email);
        setInsta(data.data.customer.instagram);
        setFb(data.data.customer.facebook);
        setTiktok(data.data.customer.tik_tok);
        setTwitter(data.data.customer.twitter);
        setYoutube(data.data.customer.youtube);
        setLinkedin(data.data.customer.linkedin);
        setGoogle(data.data.customer.google_reviews);
        setWebsite(data.data.customer.website);
        setDummy(data.data.customer.dummy);
        setAddress(data.data.customer.address_link);
        setGallery1(data.data.customer.gallery_link1);
        setGallery2(data.data.customer.gallery_link2);
        setGallery3(data.data.customer.gallery_link3);
        setGallery4(data.data.customer.gallery_link4);
        setGallery5(data.data.customer.gallery_link5);
        setPdf(data.data.customer.pdf);
      })
      .catch(() => {
        console.log("Something went wrong");
      });
  }, []);
  let profile_image1 = (e) => {
    setImage(e.target.files[0]);
  };
  let name1 = (e) => {
    setData(e.target.value);
  };
  let designation1 = (e) => {
    setDesignation(e.target.value);
  };

  let description1 = (e) => {
    setDescription(e.target.value);
  };
  let number1 = (e) => {
    setNumber(e.target.value);
  };

  let whatsapp1 = (e) => {
    setWhatsapp(e.target.value);
  };
  let email1 = (e) => {
    setEmail(e.target.value);
  };
  let insta1 = (e) => {
    setInsta(e.target.value);
  };
  let fb1 = (e) => {
    setFb(e.target.value);
  };

  let tiktok1 = (e) => {
    setTiktok(e.target.value);
  };
  let twitter1 = (e) => {
    setTwitter(e.target.value);
  };

  let youtube1 = (e) => {
    setYoutube(e.target.value);
  };

  let linkedin1 = (e) => {
    setLinkedin(e.target.value);
  };

  let google1 = (e) => {
    setGoogle(e.target.value);
  };

  let website1 = (e) => {
    setWebsite(e.target.value);
  };

  let dummy1 = (e) => {
    setDummy(e.target.value);
  };
  let address1 = (e) => {
    setAddress(e.target.value);
  };

  let gallery1 = (e) => {
    setGallery1(e.target.files[0]);
  };

  let gallery2 = (e) => {
    setGallery2(e.target.files[0]);
  };

  let gallery3 = (e) => {
    setGallery3(e.target.files[0]);
  };

  let gallery4 = (e) => {
    setGallery4(e.target.files[0]);
  };

  let gallery5 = (e) => {
    setGallery5(e.target.files[0]);
  };

  let pdf1 = (e) => {
    setPdf(e.target.files[0]);
  };

  let nav = useNavigate();

  if (!isAuthenticated()) {
    nav("/");
    return null; // Prevent rendering the component if not authenticated
  }
  let formhandle = async () => {
    let formData = new FormData();
    // formData.append("_method", "PUT");

    formData.append("profile_image", profile_image);
    formData.append("name", name);
    formData.append("designation", designation);
    formData.append("description", description);
    formData.append("phone", phone);
    formData.append("whatsapp", whatsapp);
    formData.append("email", email);
    formData.append("instagram", instagram);
    formData.append("facebook", facebook);
    formData.append("tik_tok", tik_tok);
    formData.append("twitter", twitter);
    formData.append("youtube", youtube);
    formData.append("linkedin", linkedin);
    formData.append("google_reviews", google_reviews);
    formData.append("website", website);
    formData.append("dummy", dummy);
    formData.append("address_link", address_link);
    formData.append("gallery_link1", gallery_link1);
    formData.append("gallery_link2", gallery_link2);
    formData.append("gallery_link3", gallery_link3);
    formData.append("gallery_link4", gallery_link4);
    formData.append("gallery_link5", gallery_link5);
    formData.append("pdf", pdf);
    formData.append("id", abc);

    console.log("FormData:", formData);

    const response = await axios.post(
      "https://api.unicorndigitalmarketing.in/api/api_v1.php?type=editCustomer",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(response);

    nav("/admin");
  };
  return (
    <div>
      <h3 className="mb-5">Edit Customer</h3>
      <div className="d-flex justify-content-between">
        <form className="w-100">
          <div className="w-50 d-flex flex-column gap-3">
            {profile_image && (
              <Avatar
                alt="Profile Image"
                src={`https://api.unicorndigitalmarketing.in/api/uploads/Customer/${profile_image}`} // Set the source URL of the image
                sx={{ width: 100, height: 100 }}
              />
            )}
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <MDBFile
                label="Profile Image"
                size="lg"
                id="profile_image"
                accept=".jpg, .png, .jpeg, .webp"
                onChange={profile_image1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Name"
                id="name"
                value={name}
                onChange={name1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Designation"
                id="designation"
                value={designation}
                onChange={designation1}
              />
            </Box>

            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Description"
                id="description"
                value={description}
                onChange={description1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Number"
                id="phone"
                value={phone}
                onChange={number1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Whatsapp Number"
                id="whatsapp"
                value={whatsapp}
                onChange={whatsapp1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Email"
                id="email"
                onChange={email1}
                value={email}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Instagram"
                id="instagram"
                value={instagram}
                onChange={insta1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Facebook"
                id="facebook"
                value={facebook}
                onChange={fb1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="TikTok"
                id="tik_tok"
                value={tik_tok}
                onChange={tiktok1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Twitter"
                id="twitter"
                value={twitter}
                onChange={twitter1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Youtube"
                value={youtube}
                id="youtube"
                onChange={youtube1}
              />
            </Box>
          </div>
          <div className="w-50 d-flex flex-column gap-3 mt-3">
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Linkedin"
                id="linkedin"
                value={linkedin}
                onChange={linkedin1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Google Review"
                id="google_reviews"
                value={google_reviews}
                onChange={google1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Website"
                id="website"
                value={website}
                onChange={website1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Add to Contact"
                id="dummy"
                value={dummy}
                onChange={dummy1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Address"
                id="address_link"
                value={address_link}
                onChange={address1}
              />
            </Box>
            {profile_image && (
              <Avatar
                alt="Profile Image"
                src={`https://api.unicorndigitalmarketing.in/api/uploads/Customer_Gallery/${gallery_link1}`} // Set the source URL of the image
                sx={{ width: 100, height: 100 }}
              />
            )}
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <MDBFile
                label="Galley Image"
                size="lg"
                id="gallery_link1"
                accept=".jpg, .png, .jpeg, .webp"
                onChange={gallery1}
              />
            </Box>
            {profile_image && (
              <Avatar
                alt="Profile Image"
                src={`https://api.unicorndigitalmarketing.in/api/uploads/Customer_Gallery/${gallery_link2}`} // Set the source URL of the image
                sx={{ width: 100, height: 100 }}
              />
            )}
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <MDBFile
                label="Galley Image"
                size="lg"
                id="gallery_link2"
                accept=".jpg, .png, .jpeg, .webp"
                onChange={gallery2}
              />
            </Box>
            {profile_image && (
              <Avatar
                alt="Profile Image"
                src={`https://api.unicorndigitalmarketing.in/api/uploads/Customer_Gallery/${gallery_link3}`} // Set the source URL of the image
                sx={{ width: 100, height: 100 }}
              />
            )}
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <MDBFile
                label="Galley Image"
                size="lg"
                id="gallery_link3"
                accept=".jpg, .png, .jpeg, .webp"
                onChange={gallery3}
              />
            </Box>
            {profile_image && (
              <Avatar
                alt="Profile Image"
                src={`https://api.unicorndigitalmarketing.in/api/uploads/Customer_Gallery/${gallery_link4}`} // Set the source URL of the image
                sx={{ width: 100, height: 100 }}
              />
            )}
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <MDBFile
                label="Galley Image"
                size="lg"
                id="gallery_link4"
                accept=".jpg, .png, .jpeg, .webp"
                onChange={gallery4}
              />
            </Box>
            {profile_image && (
              <Avatar
                alt="Profile Image"
                src={`https://api.unicorndigitalmarketing.in/api/uploads/Customer_Gallery/${gallery_link5}`} // Set the source URL of the image
                sx={{ width: 100, height: 100 }}
              />
            )}
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <MDBFile
                label="Galley Image"
                size="lg"
                id="gallery_link5"
                accept=".jpg, .png, .jpeg, .webp"
                onChange={gallery5}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <MDBFile
                label="Upload Pdf"
                size="lg"
                id="pdf"
                onChange={pdf1}
                accept=".pdf"
              />
            </Box>

            <Button
              className="w-100 h-25"
              variant="contained"
              onClick={formhandle}
              endIcon={<SendIcon />}
            >
              Send
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Editcustomer;
