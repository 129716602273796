import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MDBFile } from "mdb-react-ui-kit";
import { useAuth } from "../AuthContext";

const Addcustomer = () => {
  const { isAuthenticated } = useAuth();
  let [profile_image, setImage] = useState();
  let [name, setData] = useState("");
  let [designation, setDesignation] = useState("");
  let [description, setDescription] = useState("");
  let [phone, setNumber] = useState("");
  let [whatsapp, setWhatsapp] = useState("");
  let [email, setEmail] = useState("");
  let [instagram, setInsta] = useState("");
  let [facebook, setFb] = useState("");
  let [tik_tok, setTiktok] = useState("");
  let [twitter, setTwitter] = useState("");
  let [youtube, setYoutube] = useState("");
  let [linkedin, setLinkedin] = useState("");
  let [google_reviews, setGoogle] = useState("");
  let [website, setWebsite] = useState("");
  let [dummy, setDummy] = useState("");
  let [address_link, setAddress] = useState("");
  let [gallery_link1, setGallery1] = useState();
  let [gallery_link2, setGallery2] = useState();
  let [gallery_link3, setGallery3] = useState();
  let [gallery_link4, setGallery4] = useState();
  let [gallery_link5, setGallery5] = useState();
  let [pdf, setPdf] = useState();

  let profile_image1 = (e) => {
    setImage(e.target.files[0]);
  };
  let name1 = (e) => {
    setData(e.target.value);
  };
  let designation1 = (e) => {
    setDesignation(e.target.value);
  };

  let description1 = (e) => {
    setDescription(e.target.value);
  };
  let number1 = (e) => {
    setNumber(e.target.value);
  };

  let whatsapp1 = (e) => {
    setWhatsapp(e.target.value);
  };
  let email1 = (e) => {
    setEmail(e.target.value);
  };
  let insta1 = (e) => {
    setInsta(e.target.value);
  };
  let fb1 = (e) => {
    setFb(e.target.value);
  };

  let tiktok1 = (e) => {
    setTiktok(e.target.value);
  };
  let twitter1 = (e) => {
    setTwitter(e.target.value);
  };

  let youtube1 = (e) => {
    setYoutube(e.target.value);
  };

  let linkedin1 = (e) => {
    setLinkedin(e.target.value);
  };

  let google1 = (e) => {
    setGoogle(e.target.value);
  };

  let website1 = (e) => {
    setWebsite(e.target.value);
  };

  let dummy1 = (e) => {
    setDummy(e.target.value);
  };
  let address1 = (e) => {
    setAddress(e.target.value);
  };

  let gallery1 = (e) => {
    setGallery1(e.target.files[0]);
  };

  let gallery2 = (e) => {
    setGallery2(e.target.files[0]);
  };

  let gallery3 = (e) => {
    setGallery3(e.target.files[0]);
  };

  let gallery4 = (e) => {
    setGallery4(e.target.files[0]);
  };

  let gallery5 = (e) => {
    setGallery5(e.target.files[0]);
  };

  let pdf1 = (e) => {
    setPdf(e.target.files[0]);
  };

  let nav = useNavigate();

  if (!isAuthenticated()) {
    nav("/");
    return null; // Prevent rendering the component if not authenticated
  }
  let formhandle = async () => {
    // Validation function to check file types and sizes
    const validateFile = (file, allowedTypes, maxSize) => {
      const fileType = file.type.split("/")[1];
      const fileSize = file.size / 1024 / 1024; // Convert size to MB

      if (!allowedTypes.includes(fileType)) {
        throw new Error(`Invalid file type for ${file.name}.`);
      }

      if (fileSize > maxSize) {
        throw new Error(
          `File size exceeds the maximum limit for ${file.name}.`
        );
      }
    };

    let formData = new FormData();
    formData.append("profile_image", profile_image);
    formData.append("name", name);
    formData.append("designation", designation);
    formData.append("description", description);
    formData.append("phone", phone);
    formData.append("whatsapp", whatsapp);
    formData.append("email", email);
    formData.append("instagram", instagram);
    formData.append("facebook", facebook);
    formData.append("tik_tok", tik_tok);
    formData.append("twitter", twitter);
    formData.append("youtube", youtube);
    formData.append("linkedin", linkedin);
    formData.append("google_reviews", google_reviews);
    formData.append("website", website);
    formData.append("dummy", dummy);
    formData.append("address_link", address_link);
    formData.append("gallery_link1", gallery_link1);
    formData.append("gallery_link2", gallery_link2);
    formData.append("gallery_link3", gallery_link3);
    formData.append("gallery_link4", gallery_link4);
    formData.append("gallery_link5", gallery_link5);
    formData.append("pdf", pdf);
    // ... (other form fields)

    try {
      // Validate profile_image
      validateFile(profile_image, ["png", "jpg", "jpeg", "webp"], 5); // Allow only image files up to 5MB

      // Validate gallery images
      [
        gallery_link1,
        gallery_link2,
        gallery_link3,
        gallery_link4,
        gallery_link5,
      ].forEach((galleryImage) => {
        if (galleryImage) {
          validateFile(galleryImage, ["png", "jpg", "jpeg", "webp"], 5); // Allow only image files up to 5MB
        }
      });

      // Validate PDF
      if (pdf) {
        validateFile(pdf, ["pdf"], 5); // Allow only PDF files up to 5MB
      }

      // If validation passes, make the API call
      const res = await axios.post(
        "https://api.unicorndigitalmarketing.in/api/api_v1.php?type=saveCustomer",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      window.alert("Customer added Successfully");
      nav("/admin");
    } catch (error) {
      console.error(error.message);
      // Handle validation error (e.g., display an error message to the user)
    }
  };

  return (
    <div>
      <h3 className="mb-5">Add Customer</h3>
      <div className="d-flex justify-content-between">
        <form className="w-100">
          <div className="w-50 d-flex flex-column gap-3">
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <MDBFile
                label="Profile Image"
                size="lg"
                id="profile_image"
                accept=".jpg, .png, .jpeg, .webp"
                onChange={profile_image1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField fullWidth label="Name" id="name" onChange={name1} />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Designation"
                id="designation"
                onChange={designation1}
              />
            </Box>

            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Description"
                id="description"
                onChange={description1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Number"
                id="phone"
                onChange={number1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Whatsapp Number"
                id="whatsapp"
                onChange={whatsapp1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField fullWidth label="Email" id="email" onChange={email1} />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Instagram"
                id="instagram"
                onChange={insta1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Facebook"
                id="facebook"
                onChange={fb1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="TikTok"
                id="tik_tok"
                onChange={tiktok1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Twitter"
                id="twitter"
                onChange={twitter1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Youtube"
                id="youtube"
                onChange={youtube1}
              />
            </Box>
          </div>
          <div className="w-50 d-flex flex-column gap-3 mt-3">
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Linkedin"
                id="linkedin"
                onChange={linkedin1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Google Review"
                id="google_reviews"
                onChange={google1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Website"
                id="website"
                onChange={website1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Add to Contact"
                id="dummy"
                onChange={dummy1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                fullWidth
                label="Address"
                id="address_link"
                onChange={address1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <MDBFile
                label="Galley Image"
                accept=".jpg, .png, .jpeg, .webp"
                size="lg"
                id="gallery_link1"
                onChange={gallery1}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <MDBFile
                label="Galley Image"
                accept=".jpg, .png, .jpeg, .webp"
                size="lg"
                id="gallery_link2"
                onChange={gallery2}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <MDBFile
                label="Galley Image"
                accept=".jpg, .png, .jpeg, .webp"
                size="lg"
                id="gallery_link3"
                onChange={gallery3}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <MDBFile
                label="Galley Image"
                accept=".jpg, .png, .jpeg, .webp"
                size="lg"
                id="gallery_link4"
                onChange={gallery4}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <MDBFile
                label="Galley Image"
                accept=".jpg, .png, .jpeg, .webp"
                size="lg"
                id="gallery_link5"
                onChange={gallery5}
              />
            </Box>
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <MDBFile
                label="Upload Pdf"
                accept=".pdf"
                size="lg"
                id="pdf"
                onChange={pdf1}
              />
            </Box>

            <Button
              className="w-100 h-25"
              variant="contained"
              onClick={formhandle}
              endIcon={<SendIcon />}
            >
              Send
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Addcustomer;
