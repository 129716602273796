import React, { useState } from "react";
import { RiDashboardLine, RiUserSearchLine } from "react-icons/ri";
import { Outlet, Link } from "react-router-dom";
import { Layout, Menu, Button, theme } from "antd";
import { CgToggleSquareOff, CgToggleSquare } from "react-icons/cg";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { MdOutlineWeb } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext"; // Import the useAuth hook
import LogoutButton from "./LogoutButton";
import "./css/style.css";

const { Header, Sider, Content } = Layout;
const Main = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { isAuthenticated, logout } = useAuth(); // Use the useAuth hook
  const navigate = useNavigate();

  // Check authentication status and redirect to login if not authenticated
  if (!isAuthenticated()) {
    navigate("/");
    return null; // Prevent rendering the component if not authenticated
  }

  const handleLogout = () => {
    // Call the logout function from the context
    logout();
    // Redirect to login page after logout
    navigate("/");
  };

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical">
          <h2 className="text-white text-center py-4 fs-5 mb-0 d-flex align-items-center justify-content-center">
            <span className="sm-logo">UDM</span>
            <span className="lg-logo">Unicorn Digital Marketing</span>
          </h2>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[""]}
          onClick={({ key }) => {
            if (key === "signout") {
              handleLogout();
            } else {
              navigate(key);
            }
          }}
          items={[
            {
              key: "",
              icon: <RiDashboardLine className="fs-4" />,
              label: "Dashboard",
            },
            {
              key: "customers",
              icon: <RiUserSearchLine className="fs-4" />,
              label: "Customers",
              children: [
                {
                  key: "addcustomer",
                  icon: <AiOutlineUsergroupAdd className="fs-4" />,
                  label: "Add Customer",
                },
              ],
            },
            {
              key: "createpage",
              icon: <MdOutlineWeb className="fs-4" />,
              label: "Create Customer Page",
            },
            {
              key: "signout",
              icon: <CgToggleSquareOff className="fs-4" />,
              label: "Sign Out",
            },
          ]}
        />
      </Sider>
      <Layout>
        <Header
          className="d-flex justify-content-between ps-2 pe-5"
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <Button
            type="text"
            icon={
              collapsed ? (
                <CgToggleSquare className="fs-1" />
              ) : (
                <CgToggleSquareOff className="fs-1" />
              )
            }
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "18px",
              width: 64,
              height: 64,
            }}
          />
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Main;
