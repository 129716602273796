import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom"; // Import BrowserRouter
import { AuthProvider } from "./AuthContext"; // Import AuthProvider
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <AuthProvider>
        {" "}
        <App />
      </AuthProvider>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
