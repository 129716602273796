// Pagecreator.js
import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import "./css/style.css";
import { Navigate } from "react-router-dom";
import { useAuth } from "../AuthContext"; // Import the useAuth hook

const Pagecreator = () => {
  const { isAuthenticated } = useAuth(); // Use the useAuth hook
  const [tableData, setTableData] = useState([]);
  const [generatedComponents, setGeneratedComponents] = useState([]);
  const navigate = useNavigate();

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "name", headerName: "Name", width: "200" },
    { field: "phone", headerName: "Number", width: "150" },
    { field: "email", headerName: "Email", width: "250" },
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (x) => {
        return (
          <div>
            <Stack direction="row" spacing={1}>
              <Button
                variant="contained"
                endIcon={<SendIcon />}
                onClick={() =>
                  handleGenerateComponent(x.id, x.row.name, x.row.designation)
                }
              >
                Create Page
              </Button>
            </Stack>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetch(
      "https://api.unicorndigitalmarketing.in/api/api_v1.php?type=getAllCustomer"
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setTableData(res.customers);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (!isAuthenticated()) {
    return <Navigate to="/" />;
  }

  const handleGenerateComponent = (id, name, designation) => {
    if (id <= 61) {
      navigate(`/client/${id}`);
    } else {
      navigate(`/client/${id}/${name}/${designation}`);
    }
  };

  return (
    <div style={{ height: 700, width: "100%" }}>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={10}
        slots={{
          toolbar: GridToolbar,
        }}
      />
    </div>
  );
};

export default Pagecreator;
