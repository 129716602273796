import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Components/login";
import Main from "./Components/Main";
import Dashboard from "./Components/Dashboard";
import Addcustomer from "./Components/Addcustomer";
import Editcustomer from "./Components/Editcustomer";
import Pagecreator from "./Components/Pagecreator";
import Client4 from "./Components/Clients/Client4";

const App = () => {
  return (
    <Routes>
      {/* Authentication Routes */}
      <Route path="/" element={<Login />} />

      {/* Main Application Routes */}
      <Route path="admin" element={<Main />}>
        <Route index element={<Dashboard />} />
        <Route path="addcustomer" element={<Addcustomer />} />
        <Route path="editcustomer/:abc" element={<Editcustomer />} />
        <Route path="createpage" element={<Pagecreator />} />
      </Route>

      {/* Client Routes */}
      <Route path="client/:abc" element={<Client4 />} />
      <Route path="client/:abc/:name/:designation" element={<Client4 />} />
    </Routes>
  );
};

export default App;
