import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "../css/card.css"; // Import your CSS file
import Button from "@mui/material/Button";
import Emailimg from "../../img/email.webp";
import Instaimg from "../../img/Insta.webp";
import Twitterimg from "../../img/twitter.webp";
import Webimg from "../../img/Webs.webp";
import Linkimg from "../../img/Link.webp";
import Callimg from "../../img/call.webp";
import Linkedinimg from "../../img/LinkedIn.webp";
import Contactimg from "../../img/contact.webp";
import Facebookimg from "../../img/facebook.webp";
import Tiktokimg from "../../img/tiktok.webp";
import Youtubeimg from "../../img/youtube.webp";
import Reviewimg from "../../img/Review.webp";
import Locationimg from "../../img/Location.webp";
import Whatsappimg from "../../img/Whatsapp.webp";
import logoimg from "../../img/logo.png";
import Downloadimg from "../../img/download.webp";

const Client2 = () => {
  let [profile_image, setImage] = useState("");
  let [name, setData] = useState("");
  let [designation, setDesignation] = useState("");
  let [description, setDescription] = useState("");
  let [phone, setNumber] = useState("");
  let [Whatsapp, setWhatsapp] = useState("");
  let [email, setEmail] = useState("");
  let [instagram, setInsta] = useState("");
  let [facebook, setFb] = useState("");
  let [tik_tok, setTiktok] = useState("");
  let [twitter, setTwitter] = useState("");
  let [youtube, setYoutube] = useState("");
  let [linkedin, setLinkedin] = useState("");
  let [google_reviews, setGoogle] = useState("");
  let [website, setWebsite] = useState("");
  let [dummy, setDummy] = useState("");
  let [address_link, setAddress] = useState("");
  let [gallery_link1, setGallery1] = useState("");
  let [gallery_link2, setGallery2] = useState("");
  let [gallery_link3, setGallery3] = useState("");
  let [gallery_link4, setGallery4] = useState("");
  let [gallery_link5, setGallery5] = useState("");
  let [pdf, setPdf] = useState("");

  let { abc, name: clientName, designation: clientDesignation } = useParams();

  useEffect(() => {
    axios
      .post(
        `https://api.unicorndigitalmarketing.in/api/api_v1.php?type=getSingleCustomer`,
        {
          id: abc,
          name: clientName,
          designation: clientDesignation,
        }
      )
      .then((data) => {
        setImage(data.data.customer.profile_image);
        setData(data.data.customer.name);
        setDesignation(data.data.customer.designation);
        setDescription(data.data.customer.description);
        setNumber(data.data.customer.phone);
        setWhatsapp(data.data.customer.whatsapp);
        setEmail(data.data.customer.email);
        setInsta(data.data.customer.instagram);
        setFb(data.data.customer.facebook);
        setTiktok(data.data.customer.tik_tok);
        setTwitter(data.data.customer.twitter);
        setYoutube(data.data.customer.youtube);
        setLinkedin(data.data.customer.linkedin);
        setGoogle(data.data.customer.google_reviews);
        setWebsite(data.data.customer.website);
        setDummy(data.data.customer.dummy);
        setAddress(data.data.customer.address_link);
        setGallery1(data.data.customer.gallery_link1);
        setGallery2(data.data.customer.gallery_link2);
        setGallery3(data.data.customer.gallery_link3);
        setGallery4(data.data.customer.gallery_link4);
        setGallery5(data.data.customer.gallery_link5);
        setPdf(data.data.customer.pdf);
      })
      .catch(() => {
        console.log("Something went wrong");
      });
  }, [abc, clientName, clientDesignation]);

  useEffect(() => {
    axios
      .post(
        `https://api.unicorndigitalmarketing.in/api/api_v1.php?type=getSingleCustomer`,
        {
          id: abc,
        }
      )
      .then((data) => {
        setImage(data.data.customer.profile_image);
        setData(data.data.customer.name);
        setDesignation(data.data.customer.designation);
        setDescription(data.data.customer.description);
        setNumber(data.data.customer.phone);
        setWhatsapp(data.data.customer.whatsapp);
        setEmail(data.data.customer.email);
        setInsta(data.data.customer.instagram);
        setFb(data.data.customer.facebook);
        setTiktok(data.data.customer.tik_tok);
        setTwitter(data.data.customer.twitter);
        setYoutube(data.data.customer.youtube);
        setLinkedin(data.data.customer.linkedin);
        setGoogle(data.data.customer.google_reviews);
        setWebsite(data.data.customer.website);
        setDummy(data.data.customer.dummy);
        setAddress(data.data.customer.address_link);
        setGallery1(data.data.customer.gallery_link1);
        setGallery2(data.data.customer.gallery_link2);
        setGallery3(data.data.customer.gallery_link3);
        setGallery4(data.data.customer.gallery_link4);
        setGallery5(data.data.customer.gallery_link5);
        setPdf(data.data.customer.pdf);
      })
      .catch(() => {
        console.log("Something went wrong");
      });
  }, [abc]);

  const handleDownload = () => {
    if (pdf) {
      window.open(pdf, "_blank");
    } else {
      console.error("PDF URL is not available");
    }
  };

  return (
    <div className="pCard">
      <section className="section_card">
        <div className="hello">
          <img
            src={`https://api.unicorndigitalmarketing.in/api/uploads/Customer/${profile_image}`}
            alt=""
          />
          <h2>{name}</h2>
          <h5>{designation}</h5>
          <p>{description}</p>
        </div>
        <div className="social_img">
          <a href={`tel:${phone}`}>
            <img src={Callimg} alt="Call" />
          </a>
          <a href={`mailto:${email}`}>
            <img src={Emailimg} alt="Email" />
          </a>
          <a href={website} className="web_img">
            <img src={Webimg} alt="Website" />
          </a>
          <a href={instagram}>
            <img src={Instaimg} alt="Instagram" />
          </a>
          <a href={twitter}>
            <img src={Twitterimg} alt="Twitter" />
          </a>
          <a href={linkedin}>
            <img src={Linkedinimg} alt="Linkedin" className="linkedin_img" />
          </a>
        </div>
        <div className="social_links">
          <div className="d-flex gap-3 flex-column">
            <div className="d-flex justify-content-between w-100">
              <a href={dummy} className="card_btn">
                <Button variant="contained" className="card_button">
                  <h6 className="">
                    <img src={Contactimg} alt="Call" />
                  </h6>
                  <h5>Add me to your contacts</h5>
                </Button>
              </a>
            </div>
          </div>
          <div className="d-flex gap-3 flex-column">
            <div className="d-flex justify-content-between w-100">
              <a href={`tel:${phone}`} className="card_btn">
                <Button variant="contained" className="card_button">
                  <h6 className="">
                    <img src={Callimg} alt="Call" />
                  </h6>
                  <h5>Give me a call</h5>
                </Button>
              </a>
            </div>
          </div>
          <div className="d-flex gap-3 flex-column">
            <div className="d-flex justify-content-between w-100">
              <a href={`mailto:${email}`} className="card_btn">
                <Button variant="contained" className="card_button">
                  <h6 className="">
                    <img src={Emailimg} alt="Call" />
                  </h6>
                  <h5>Write me an email</h5>
                </Button>
              </a>
            </div>
          </div>
          <div className="d-flex gap-3 flex-column">
            <div className="d-flex justify-content-between w-100">
              <a href={`https://wa.me/${Whatsapp}`} className="card_btn">
                <Button variant="contained" className="card_button">
                  <h6 className="">
                    <img src={Whatsappimg} alt="Call" />
                  </h6>
                  <h5>Whatsapp</h5>
                </Button>
              </a>
            </div>
          </div>
          <div className="d-flex gap-3 flex-column">
            <div className="d-flex justify-content-between w-100">
              <a href={website} className="card_btn">
                <Button variant="contained" className="card_button">
                  <h6 className="">
                    <img src={Webimg} alt="Call" />
                  </h6>
                  <h5>Website</h5>
                </Button>
              </a>
            </div>
          </div>
        </div>
        <div className="social text-center my-5">
          <h2 className="mb-5">Get To Know Me</h2>
          <div className="social_links">
            <div className="d-flex gap-3 flex-column">
              <div className="d-flex justify-content-between w-100">
                <a href={google_reviews} className="card_btn">
                  <Button variant="contained" className="card_button">
                    <h6 className="">
                      <img src={Reviewimg} alt="Linkedin" />
                    </h6>
                    <h5>Google Reviews</h5>
                  </Button>
                </a>
              </div>
            </div>
            <div className="d-flex gap-3 flex-column">
              <div className="d-flex justify-content-between w-100">
                <a href={facebook} className="card_btn">
                  <Button variant="contained" className="card_button">
                    <h6 className="">
                      <img src={Facebookimg} alt="Facebook" />
                    </h6>
                    <h5>Facebook</h5>
                  </Button>
                </a>
              </div>
            </div>
            <div className="d-flex gap-3 flex-column">
              <div className="d-flex justify-content-between w-100">
                <a href={instagram} className="card_btn">
                  <Button variant="contained" className="card_button">
                    <h6 className="">
                      <img src={Instaimg} alt="Instagram" />
                    </h6>
                    <h5>Instagram</h5>
                  </Button>
                </a>
              </div>
            </div>
            <div className="d-flex gap-3 flex-column">
              <div className="d-flex justify-content-between w-100">
                <a href={twitter} className="card_btn">
                  <Button variant="contained" className="card_button">
                    <h6 className="">
                      <img src={Twitterimg} alt="Twitter" />
                    </h6>
                    <h5>X</h5>
                  </Button>
                </a>
              </div>
            </div>
            <div className="d-flex gap-3 flex-column">
              <div className="d-flex justify-content-between w-100">
                <a href={linkedin} className="card_btn">
                  <Button variant="contained" className="card_button">
                    <h6 className="">
                      <img
                        src={Linkedinimg}
                        alt="Linkedin"
                        className="linkedin_img"
                      />
                    </h6>
                    <h5>LinkedIn</h5>
                  </Button>
                </a>
              </div>
            </div>
            <div className="d-flex gap-3 flex-column">
              <div className="d-flex justify-content-between w-100">
                <a href={tik_tok} className="card_btn">
                  <Button variant="contained" className="card_button">
                    <h6 className="">
                      <img src={Tiktokimg} alt="Linkedin" />
                    </h6>
                    <h5>TikTok</h5>
                  </Button>
                </a>
              </div>
            </div>
            <div className="d-flex gap-3 flex-column">
              <div className="d-flex justify-content-between w-100">
                <a href={youtube} className="card_btn">
                  <Button variant="contained" className="card_button">
                    <h6 className="">
                      <img src={Youtubeimg} alt="Linkedin" />
                    </h6>
                    <h5>YouTube</h5>
                  </Button>
                </a>
              </div>
            </div>
            <div className="d-flex gap-3 flex-column">
              <div className="d-flex justify-content-between w-100">
                <a href={address_link} className="card_btn">
                  <Button variant="contained" className="card_button">
                    <h6 className="">
                      <img src={Locationimg} alt="Linkedin" />
                    </h6>
                    <h5>Location</h5>
                  </Button>
                </a>
              </div>
            </div>
            {/* <div className="d-flex gap-3 flex-column">
              <div className="d-flex justify-content-between w-100">
                <a href={dummy} className="card_btn">
                  <Button variant="contained" className="card_button">
                    <h6 className="">
                      <img src={Linkimg} alt="Linkedin" />
                    </h6>
                    <h5>Other...</h5>
                  </Button>
                </a>
              </div>
            </div> */}
          </div>
        </div>
        <div className="w-100 mt-4 p-3 img_section">
          <h3>Gallery</h3>
          <div className="img_container">
            <img
              src={`https://api.unicorndigitalmarketing.in/api/uploads/Customer_Gallery/${gallery_link1}`}
              alt="My Image"
            />
            <img
              src={`https://api.unicorndigitalmarketing.in/api/uploads/Customer_Gallery/${gallery_link2}`}
              alt="My Image"
            />
            <img
              src={`https://api.unicorndigitalmarketing.in/api/uploads/Customer_Gallery/${gallery_link3}`}
              alt="My Image"
            />
            <img
              src={`https://api.unicorndigitalmarketing.in/api/uploads/Customer_Gallery/${gallery_link4}`}
              alt="My Image"
            />
            <img
              src={`https://api.unicorndigitalmarketing.in/api/uploads/Customer_Gallery/${gallery_link5}`}
              alt="My Image"
            />
          </div>
        </div>
        <div className="d-flex gap-3 flex-colum mt-4">
          <div className="d-flex justify-content-between w-100">
            <a
              href={`https://api.unicorndigitalmarketing.in/api/uploads/pdf/${pdf}`}
              className="card_btn w-100"
              target="_blank"
              download={pdf}
            >
              <Button
                variant="contained"
                className="card_button align-items-center w-100"
              >
                <img src={Downloadimg} alt="Download" />
                <h5 className="text-center d-flex gap-3 text-transform-capitalize">
                  Download PDF
                </h5>
              </Button>
            </a>
          </div>
        </div>
        <div className="mt-5">
          <p>
            <span className="unicorn_link">
              <a href="https://ecommerce.unicorndigitalmarketing.in/">
                <button>Get Your Card</button>
              </a>
            </span>
          </p>
          <p className="unicorn_link">Powerd by</p>
          <div className="text-center">
            <a href="https://www.unicorndigitalmarketing.in/">
              <img src={logoimg} alt="Unicorn Digital Marketing" height={40} />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Client2;
