import style from "./css/login.module.css";
import logo from "../img/logo.png";
import bgimg from "../img/bg_img.svg";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const [username, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      if (!username || !password) {
        setError("Please enter both username and password.");
        return;
      } else {
        let formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);

        const response = await axios.post(
          "https://api.unicorndigitalmarketing.in/api/api_v1.php?type=login",
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = response.data;
        if (data.status) {
          // Successful login
          const token = data.token;

          // Use the login function from the context to set the token
          login(token);

          navigate("/admin");
        } else {
          // Invalid credentials
          setError("Invalid credentials. Please try again.");
        }
      }
    } catch (error) {
      // Handle network or other errors
      console.error("Error:", error);
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id={style.login}>
      <div className={style.bgimg}>
        <img src={bgimg} alt="background image indicates login" />
      </div>
      <div className={style.login_div}>
        <div className={style.logo}>
          <img src={logo} alt="Unicorn Digital Services Logo" />
        </div>
        <div className={style.login_data}>
          <h1>Login</h1>
          <h6>Admin Panel</h6>
          <form onSubmit={handleLogin}>
            <input
              type="email"
              placeholder="Email"
              value={username}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              type="submit"
              value="Login"
              className={style.btn}
              disabled={loading}
            />
          </form>
          {error && <p style={{ color: "red" }}>{error}</p>}
        </div>
      </div>
    </div>
  );
};

export default Login;
